/**
 * Reducer that manages the state of RequestCoach
 */

// eslint-disable-next-line import/no-cycle

// Request Coach Action types
import {
  REQUEST_COACH,
  REQUEST_COACH_DONE,
  REQUEST_COACH_ERROR,
  GET_COACH_REQUEST_STATUS,
  GET_COACH_REQUEST_STATUS_DONE,
  GET_COACH_REQUEST_STATUS_ERROR,
  CLEAR_COACH_REQUEST_STATUS_ERROR,
  AUTO_COACH_REQUEST,
  AUTO_COACH_REQUEST_DONE,
  AUTO_COACH_REQUEST_ERROR,
  COACH_UNASSIGNMENT_DONE
} from '../../actions/types';

import coachRequestStatusTypes from '../../../assets/data/dashboard/coachRequestStatusTypes';

const INITIAL_STATE = {
  status: {
    isFetching: false,
    hasError: false
  },
  request: {
    isFetching: false,
    hasError: false
  },
  autoCoach: {
    isFetching: false,
    hasError: false
  },
  coachRequestStatus: coachRequestStatusTypes.none,
  coachDetails: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_COACH:
      return {
        ...state,
        request: {
          hasError: false,
          isFetching: true
        }
      };
    case REQUEST_COACH_DONE: {
      return {
        ...state,
        request: {
          isFetching: false,
          hasError: false
        },
        coachRequestStatus: coachRequestStatusTypes.requested
      };
    }
    case COACH_UNASSIGNMENT_DONE: {
      return {
        ...state,
        request: {
          isFetching: false,
          hasError: false
        },
        coachRequestStatus: coachRequestStatusTypes.none
      };
    }
    case REQUEST_COACH_ERROR:
      return {
        ...state,
        request: {
          isFetching: false,
          hasError: true
        }
      };
    case GET_COACH_REQUEST_STATUS:
      return {
        ...state,
        status: {
          isFetching: true,
          hasError: false
        }
      };
    case GET_COACH_REQUEST_STATUS_DONE: {
      return {
        ...state,
        status: {
          isFetching: false
        },
        coachRequestStatus: action.status,
        coachDetails: action.details || {}
      };
    }
    case GET_COACH_REQUEST_STATUS_ERROR: {
      return {
        ...state,
        status: {
          isFetching: false,
          hasError: true
        }
      };
    }
    case CLEAR_COACH_REQUEST_STATUS_ERROR: {
      return {
        ...state,
        request: {
          hasError: false
        },
        autoCoach: {
          hasError: false,
          isFetching: false
        }
      };
    }
    case AUTO_COACH_REQUEST: {
      return {
        ...state,
        autoCoach: {
          hasError: false,
          isFetching: true
        },
        coachRequestStatus: coachRequestStatusTypes.inProgress
      };
    }
    case AUTO_COACH_REQUEST_DONE: {
      return {
        ...state,
        autoCoach: {
          isFetching: false,
          hasError: false
        },
        coachRequestStatus: coachRequestStatusTypes.assigned,
        coachDetails: action.coachDetails
      };
    }
    case AUTO_COACH_REQUEST_ERROR:
      return {
        ...state,
        autoCoach: {
          isFetching: false,
          hasError: true
        }
      };
    default:
      return state;
  }
};
