/* eslint-disable import/no-cycle */
import { get, isEmpty } from 'lodash';
import { getUserDetails } from './auth';
import store from '../store';

let userDetails = {};
export const _hasFeature = (featureName) => {
  const features = get(store.getState(), 'profile.profile.features', []); // TODO: make it efficient when cyclic dependency is removed
  userDetails = getUserDetails();
  return (
    (userDetails.features && userDetails.features.includes(featureName)) ||
    (!isEmpty(features) && features.includes(featureName))
  );
};

const _isProductActive = () => {
  userDetails = getUserDetails();
  return userDetails.features && userDetails.features.length > 0;
};

class FeatureService {
  _hasSfhcCoachFeature = () =>
    _hasFeature('user_allowed_to_access_health_cloud_coaching');

  _hasIntelliPlatformFeature = () =>
    _hasFeature('user_allowed_to_access_intelliplatform');

  _hasOnBoardingFlowFeature = () =>
    _hasFeature('user_allowed_to_access_onboarding_flow');

  _hasHighWorkStressGoalFeature = () =>
    _hasFeature('user_allowed_to_access_high_stress_work_goal');

  _hasDiagnosticSurveyFeature = () =>
    _hasFeature('user_allowed_to_access_diagnostic_survey');

  _hasSuicidalIdeationEndorsementAlertFeature = () =>
    _hasFeature('user_allowed_to_send_suicidal_ideation_endorsement_alert');

  _hasSuicidalAlertDisclaimerFeature = () =>
    _hasFeature('user_allowed_to_see_suicidal_alert_disclaimer');

  _hasHealthCloudAlertFeature = () =>
    _hasFeature('user_allowed_to_send_alerts_to_health_cloud');

  _hasContentAccessFeature = () =>
    _hasFeature('user_allowed_to_access_content');

  _hasAutoCoachAssignmentOnCompletingSurveyFeature = () =>
    _hasFeature(
      'user_allowed_to_access_coach_auto_assignment_on_completing_survey'
    );

  _hasAutoCoachAssignmentOnRequestClickFeature = () =>
    _hasFeature(
      'user_allowed_to_access_coach_auto_assignment_on_request_click'
    );

  _hasCoachAccessFeature = () => _hasFeature('user_allowed_to_access_coach');

  _hasCoachWelcomeMessageFeature = () =>
    _hasFeature('user_allowed_to_receive_chat_welcome_message');

  _hasOnboardingCallFeature = () =>
    _hasFeature('user_allowed_to_receive_coach_onboarding_call');

  _hasRetakeAssessmentPopupFeature = () =>
    _hasFeature('user_allowed_to_see_rescreening_popup');

  _isProductActive = () => _isProductActive();

  _hasCoachAccessOnWebFeature = () =>
    _hasFeature('user_allowed_to_access_coach_on_web');

  _hasCovid19Goal = () => _hasFeature('user_allowed_to_access_covid_19_goal');

  _hasBonusGoalFeature = () =>
    this._hasHighWorkStressGoalFeature() || this._hasCovid19Goal();

  _hasCoachChatOnWebFeature = () =>
    _hasFeature('user_allowed_to_access_coach_chat_on_web');

  _hasTestControlFeature = () =>
    _hasFeature('user_allowed_to_access_test_controls');

  _hasCoachSuggestionFeature = () =>
    _hasFeature('user_allowed_to_access_coach_suggestion');

  _hasWorkLifeRequestFeature = () =>
    _hasFeature('user_allowed_to_access_work_life_request');

  _hasVideoTherapyFeature = () =>
    _hasFeature('user_allowed_to_access_video_therapy');

  _hasInpersonTherapyFeature = () =>
    _hasFeature('user_allowed_to_access_inperson_therapy');

  _hasCatAssessmentFeature = () =>
    _hasFeature('user_allowed_to_access_cat_assessment');

  _hasMindCompanionConversationalAssessmentFeature = () =>
    _hasFeature(
      'user_allowed_to_access_mind_companion_conversational_assessment'
    );

  _hasGoalListFeature = () => _hasFeature('user_allowed_to_access_goal_list');

  _hasRetakeSurveyCardFeature = () =>
    _hasFeature('user_allowed_to_access_retake_survey_card');

  _hasBreathingExerciseFeature = () =>
    _hasFeature('user_allowed_to_access_breathing_exercise');

  _hasSelfUseFeature = () => _hasFeature('user_allowed_to_access_self_use');

  _hasConversationalAssessmentFeature = () =>
    _hasFeature('user_allowed_to_access_conversational_assessment');

  _hasContactEAPCardFeature = () =>
    _hasFeature('user_allowed_to_access_eap_info_tool');

  _hasResourcesFeature = () => _hasFeature('user_allowed_to_access_resources');

  _hasTrackerFeature = () =>
    _hasFeature('user_allowed_to_access_session_progress_tracker');

  _hasResourceTabArticlesAccessFeature = () =>
    _hasFeature('user_allowed_to_access_resources_tab_articles');

  _hasTelevideoTherapyFeature = () =>
    _hasFeature('user_allowed_to_access_chat_and_televideo_therapy');

  _hasChatTherapyFeature = () =>
    _hasFeature('user_allowed_to_access_chat_therapy');

  _hasCounselorTherapyFeature = () =>
    _hasFeature('user_allowed_to_access_counselor_therapy');

  _hasTherapyAccessFeature = () =>
    _hasFeature('user_allowed_to_access_therapy_card');

  _hasTherapyAccessWithEAPFeature = () =>
    _hasFeature('user_allowed_to_access_hr_therapy_card');

  _hasEligibilityAssessmentFeature = () =>
    _hasFeature('user_allowed_to_access_eligibility_assessment');

  _hasRedirectOnTherapyFeature = () =>
    _hasFeature('user_allowed_to_redirect_on_therapy');

  _hasWpoRequestFeature = () =>
    _hasFeature('user_allowed_to_access_wpo_request');

  _hasCustomCarePlanFeature = () =>
    _hasFeature('user_allowed_to_access_additional_resources');

  _hasAppSettingsAccessFeature = () =>
    _hasFeature('user_allowed_to_access_app_settings');

  _hasActivityFeature = () =>
    _hasFeature('user_allowed_to_access_recent_activities');

  _hasDisclaimerFeature = () =>
    _hasFeature('user_allowed_to_access_disclaimer_card');

  _hasManualTrackerFeature = () =>
    _hasFeature('user_allowed_to_access_session_manual_progress_tracker');

  _hasRflAssessmentFeature = () =>
    _hasFeature('user_allowed_to_access_rfl_assessment');

  _hasMyPlanFeature = () => _hasFeature('user_allowed_to_access_my_plan_tab');

  _hasFocusAreaFeature = () => _hasFeature('user_allowed_to_access_focus_area');

  _hasLatestAssessmentReportFeature = () =>
    _hasFeature('user_allowed_to_access_latest_assessment_report');

  _hasCarePlanPlanFeature = () =>
    _hasFeature('user_allowed_to_access_care_plan');

  _hasSkipSessionFeature = () => _hasFeature('user_allowed_to_skip_sessions');

  _hasReportWithoutSeverityFeature = () =>
    _hasFeature('user_allowed_to_access_report_without_severity');

  _hasChangeFocusAreaFeature = () =>
    _hasFeature('user_allowed_to_access_change_focus_area_button');

  _hasAppSupportAccessFeature = () =>
    _hasFeature('user_allowed_to_access_app_support');

  _hasCoachRatingAccessFeature = () =>
    _hasFeature('user_allowed_to_access_coach_feedback');

  _hasWholelifeSurveyFeature = () =>
    _hasFeature('user_allowed_to_access_wholelife_survey');

  _hasServiceTileFeature = () =>
    _hasFeature('user_allowed_to_access_additional_resources_cards');
}

export default new FeatureService();
