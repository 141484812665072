import { isNaN } from 'lodash';

export const getRetryConfig = (onRetry) => ({
  retries: 3,
  minTimeout: 1500,
  maxTimeout: 3000,
  factor: 2,
  onRetry
});

const isRetryable = (
  error,
  errorMessages = [
    'Error in storing secret key',
    'Error in fetching secret key from storage'
  ]
) => !!errorMessages.includes(error.message);

export const bailIfErrorNotRetryable = (bail) => (error) => {
  if (error && isRetryable(error)) {
    // retry in this case
    throw error;
  }
  // bail if retried 3 times OR the error is not retryable
  bail(error);
};

export const getParsedValue = (value) => {
  let parsedValue;
  try {
    parsedValue = JSON.parse(value);
  } catch (error) {
    parsedValue = value;
  }
  return parsedValue;
};
export const isEmptyValues = (value) =>
  value === undefined ||
  value === null ||
  isNaN(value) ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);
