import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import { Preferences } from '@capacitor/preferences';
// eslint-disable-next-line import/no-cycle
import { getStoredSecretKeyWithRetry } from './encryption-key';
// eslint-disable-next-line import/no-cycle
import logger from '../logger';
// eslint-disable-next-line import/no-cycle
import { isNativePlatform } from '../capacitor';
import { getParsedValue, isEmptyValues } from './helpers';

const encrypt = async (value) => {
  try {
    const key = await getStoredSecretKeyWithRetry();
    const bytes = AES.encrypt(value, key);
    logger.info('Encrypted value', 'storage.encrypt', {
      encryptedValue: bytes.toString()
    });
    return bytes.toString();
  } catch (error) {
    logger.error('Error in encrypt function', 'storage.encrypt', {
      errName: error.name,
      error
    });
    throw error;
  }
};

const decrypt = async (value) => {
  try {
    logger.info('Value to decrypt', 'storage.decrypt', { value });
    const key = await getStoredSecretKeyWithRetry();
    const bytes = AES.decrypt(value, key);
    return bytes.toString(Utf8);
  } catch (error) {
    logger.error('Error in decrypt function', 'storage.decrypt', {
      errName: error.name,
      error
    });
    throw error;
  }
};

// encrypt/decrypt only on native platforms,
// use session storage on web if webPersist is false else use localstorage
export default {
  setItem: async (key, value, webPersist = false) => {
    if (isNativePlatform) {
      try {
        // Setting value in mobile app storage
        if (!isEmptyValues(value)) {
          await Preferences.set({
            key,
            value: await encrypt(JSON.stringify({ value }))
          });
        } else {
          logger.warn(
            `Value to be saved in mobile app session storage for ${key} is empty`,
            'storage.setItem',
            { value }
          );
        }
      } catch (error) {
        logger.error('Error in setItem function', 'storage.setItem', {
          errName: error.name,
          error,
          key
        });
      }
    } else if (!isEmptyValues(value)) {
      // Setting value in web app storage
      if (webPersist) {
        window.localStorage.setItem(key, value);
      } else {
        window.sessionStorage.setItem(key, value);
      }
    } else {
      logger.warn(
        `Value to be saved in web app session storage for ${key} is empty`,
        'storage.setItem',
        { value }
      );
    }
  },
  getItem: async (key, webPersist = false) => {
    let value = null;
    if (isNativePlatform) {
      // Fetching value from mobile app storage
      try {
        ({ value } = await Preferences.get({ key }));
        if (!isEmptyValues(value)) {
          value = await decrypt(value);
          logger.info('Value is decrypted successfully', 'storage.getItem', {
            key
          });

          ({ value } = getParsedValue(value));
        } else {
          logger.warn(
            `Value for ${key} is not available in mobile app session storage`,
            'storage.getItem',
            { value }
          );
        }
      } catch (error) {
        logger.error('Error in getItem function', 'storage.getItem', {
          errName: error.name,
          error,
          key
        });
      }
    } else if (webPersist) {
      // Fetching value from web application local storage
      value = window.localStorage.getItem(key);
    } else {
      // Fetching value from web application session storage
      value = window.sessionStorage.getItem(key);
    }
    return value;
  },
  removeItem: async (key, webPersist = false) => {
    if (isNativePlatform) {
      try {
        // Removing value from mobile app storage
        logger.info('Key to remove from storage', 'storage.removeItem', {
          key
        });

        await Preferences.remove({ key });
      } catch (error) {
        logger.error('Error in removeItem function', 'storage.removeItem', {
          errName: error.name,
          error,
          key
        });
      }
    } else if (webPersist) {
      // Removing value from web application local storage
      window.localStorage.removeItem(key);
    } else {
      // Removing value from web application session storage
      window.sessionStorage.removeItem(key);
    }
  }
};
