import {
  INCREMENT_RETRY_COUNT,
  SET_MIGRATION_ERROR,
  SET_MIGRATION_STATUS
} from '../actions/types';

const initialState = {
  migrationRetryCount: 0,
  migrationSuccessful: false,
  migrationError: null
};

export default function migrationReducer(state = initialState, action) {
  switch (action.type) {
    case INCREMENT_RETRY_COUNT:
      return {
        ...state,
        migrationRetryCount: state.migrationRetryCount + 1
      };
    case SET_MIGRATION_STATUS:
      return {
        ...state,
        migrationSuccessful: action.payload
      };
    case SET_MIGRATION_ERROR:
      return {
        ...state,
        migrationError: action.payload
      };
    default:
      return state;
  }
}
