/* eslint-disable consistent-return */
import axios from 'axios';
import uuid from 'uuid/v1';
import { isEmpty, get, has } from 'lodash';
import adblockerDetection from 'adblock-detect';
import { obfuscateProps } from 'logger-customization';
import logger from '../logger';
import config from '../../config';
import { getAuth0Id } from '../auth';
import store from '../../store';
import helpers from '../helpers';
import storage from '../storage';
import { getParsedValue } from '../storage/helpers';
import { isNativePlatform } from '../capacitor';

const {
  analytics: {
    key: ANALYTICS_KEY,
    url: ANALYTICS_API_URL,
    enabled: ENABLED,
    tags: TAGS
  },
  appVersion: APP_VERSION
} = config;
const VERSION = 2;

const sendEvent = async (eventData) => {
  let isManualActivityAdded = true;
  await axios({
    baseURL: ANALYTICS_API_URL,
    url: '/trace',
    method: 'POST',
    headers: { 'X-API-KEY': ANALYTICS_KEY },
    data: eventData
  }).catch((err) => {
    isManualActivityAdded = false;
    adblockerDetection((adblockDetected) => {
      if (adblockDetected) {
        logger.info(
          'Unable to send analytics event because adblock is enabled',
          'analytics.sendEvent'
        );
      } else if (get(err, 'message', '') === 'Network Error') {
        logger.info(
          'Unable to send analytics event because of network error',
          'analytics.sendEvent'
        );
      } else {
        logger.error(
          'Error occurred while sending analytics data',
          'analytics.sendEvent',
          {
            err: JSON.parse(JSON.stringify(err))
          }
        );
      }
    });
  });
  return isManualActivityAdded;
};

const removeSessionDetails = async () => {
  await storage.removeItem('analyticsSessionDetails');
};

const getSessionDetails = async () => {
  let analyticsSessionDetails = getParsedValue(
    await storage.getItem('analyticsSessionDetails')
  );
  if (isEmpty(analyticsSessionDetails)) {
    analyticsSessionDetails = {
      sessionId: uuid(),
      timestamp: Math.floor(Date.now() / 1000)
    };
    await storage.setItem(
      'analyticsSessionDetails',
      isNativePlatform
        ? analyticsSessionDetails
        : JSON.stringify(analyticsSessionDetails)
    );
  }
  return analyticsSessionDetails;
};

const startAnalyticsSession = async () => {
  await removeSessionDetails();
  const { sessionId } = await getSessionDetails();
  return sessionId;
};

const isAnalyticsSessionExpired = async (sessionDetails) => {
  let isExpired = true;
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const analyticsSessionData = sessionDetails;
  if (analyticsSessionData) {
    const timediff = currentTimestamp - analyticsSessionData.timestamp;
    if (timediff < config.sessionTimeout) {
      // Check for 20 minutes session timeout
      isExpired = false;
      analyticsSessionData.timestamp = currentTimestamp;
      await storage.setItem(
        'analyticsSessionDetails',
        isNativePlatform
          ? analyticsSessionData
          : JSON.stringify(analyticsSessionData) // Update expiry time
      );
    }
  }
  return isExpired;
};
class AnalyticsModule {
  static async addEvent(type, category, action, properties) {
    if (ENABLED === 'true') {
      const eventData = {};
      const storeData = store.getState();
      const { getUserMigrationStatus } = helpers;

      if (!getUserMigrationStatus()) {
        logger.warn(
          'Rfl migration not completed. Not sending analytics event',
          'analytics.addEvent'
        );
        return;
      }

      eventData.type = type;
      eventData.category = category;
      eventData.action = action;
      const propertiesCopy = { ...properties };

      if (has(propertiesCopy, 'visitorId')) {
        eventData.visitorId = propertiesCopy.visitorId;
        delete propertiesCopy.visitorId;
      }

      if (!isEmpty(propertiesCopy)) {
        if (has(propertiesCopy, 'trigger')) {
          eventData.trigger = propertiesCopy.trigger;
          delete propertiesCopy.trigger;
        }
        if (
          has(propertiesCopy, 'transactionId') &&
          !isEmpty(propertiesCopy, 'transactionId')
        ) {
          eventData.transactionId = propertiesCopy.transactionId;
          delete propertiesCopy.transactionId;
        }
        eventData.properties = obfuscateProps(propertiesCopy);
      }
      eventData.context = {};
      if (window) {
        if (window.navigator && window.navigator.userAgent) {
          eventData.context.userAgent = window.navigator.userAgent;
        }
        const { obfuscateUrl } = helpers;
        const externalUrl = obfuscateUrl(
          get(eventData, 'properties.externalUrl', '')
        );
        eventData.context.page = {};
        eventData.context.page.path = isEmpty(externalUrl)
          ? window.location.pathname
          : new URL(externalUrl).pathname;
        eventData.context.page.url = isEmpty(externalUrl)
          ? window.location.href
          : externalUrl;
        if (!isEmpty(externalUrl)) {
          eventData.properties.url = eventData.context.page.path;
          delete eventData.properties.externalUrl;
        }
      }
      const userId = getAuth0Id();
      if (userId) {
        eventData.userId = userId;
        eventData.companyName = storeData.profile.profile.employer_id
          ? storeData.profile.profile.employer_id
          : storeData.profile.profile.company_name;
        eventData.eapName = get(
          storeData,
          'companyDetails.companyDetails.partner.name',
          ''
        );
      } else {
        const { getUserCompanyDetail } = helpers;
        const { companyDetail = {}, accessCode } = await getUserCompanyDetail();
        const { name } = companyDetail;
        if (!isEmpty(accessCode)) {
          eventData.accessCode = accessCode;
        }
        if (!isEmpty(name)) {
          eventData.companyName = name;
        }
      }
      eventData.id = uuid();
      eventData.tags = TAGS;
      eventData.context.version = VERSION;
      eventData.context.appVersion = APP_VERSION || null;
      eventData.timestamp = helpers.getTimeStamp(properties);
      const sessionDetails = await getSessionDetails();
      if (eventData.category !== 'logout') {
        const checkIfAnalyticsSessionExpired = await isAnalyticsSessionExpired(
          sessionDetails
        );
        eventData.sessionId = checkIfAnalyticsSessionExpired
          ? await startAnalyticsSession()
          : sessionDetails.sessionId;
      } else {
        eventData.sessionId = sessionDetails.sessionId;
      }
      if (category === 'manual') {
        const isManualActivityAdded = await sendEvent(eventData);
        if (isManualActivityAdded) {
          logger.info(
            'manual activity event send successfully',
            'analytics.sendActivityEvent'
          );
          return {
            status: 200,
            message: 'manual activity event send successfully'
          };
        }
        logger.info(
          'failed to send manual activity events',
          'analytics.sendActivityEvent'
        );
        return {
          status: 500,
          message: 'failed to send manual activity event'
        };
      }
      await sendEvent(eventData);
    }
    return null;
  }

  async track(category, action, properties = {}) {
    const type = 'track';
    const resp = await this.constructor.addEvent(
      type,
      category,
      action,
      properties
    );
    return resp;
  }

  async info(category, action, properties = {}) {
    const type = 'info';
    await this.constructor.addEvent(type, category, action, properties);
  }

  async screen(category, action, properties = {}) {
    const type = 'screen';
    await this.constructor.addEvent(type, category, action, properties);
  }
}

export default new AnalyticsModule();
