import moment from 'moment';
import { cloneDeep } from 'lodash';

export const getDurationOfLastAssessmentAndToday = (format, reports) => {
  const { timestamp } = reports[0];
  const today = moment();
  const lastAssessmentDate = moment(timestamp, 'YYYY-MM-DD');
  return today.diff(lastAssessmentDate, format);
};

export const getSortedRetakeAssessmentDuration = (retakeAssessmentDuration) => {
  const sortedRetakeAssessmentDuration = cloneDeep(retakeAssessmentDuration);
  sortedRetakeAssessmentDuration.sort((durationOne, durationTwo) => {
    const durationOneMoment = moment.duration(
      durationOne.value,
      durationOne.type
    );
    const durationTwoMoment = moment.duration(
      durationTwo.value,
      durationTwo.type
    );
    return durationOneMoment.asDays() - durationTwoMoment.asDays();
  });
  return sortedRetakeAssessmentDuration;
};

export const errorMessageInfo = {
  userDocumentFailed: 'Failed to create user document',
  unsupportedDateOfBirth: 'date_of_birth not supported',
  unsupportedDateOfBirthRfl: 'date_of_birth not supported for rfl',
  controlNumberValidationFailed:
    'control numbers are different or does not exist',
  blockedUserLoginAttemptError: 'user is blocked'
};

export const errorTypes = {
  controlNumberError: 'controlNumberError'
};
